function printASCII(){
console.log(
    function(){
/*
                       ___         ___           ___   
                      /\  \       /\  \         /\  \  
                     /::\  \     /::\  \       /::\  \ 
                    /:/\:\__\   /:/\:\  \     /:/\:\__\
                   /:/ /:/  /  /:/  \:\  \   /:/ /:/  /
Made with ❤️ by   /:/_/:/  /  /:/__/ \:\__\ /:/_/:/  / 
                  \:\/:/  /   \:\  \ /:/  / \:\/:/  /  
                   \::/__/     \:\  /:/  /   \::/__/   
                    \:\  \      \:\/:/  /     \:\  \   
                     \:\__\      \::/  /       \:\__\  
                      \/__/       \/__/         \/__/   
*/
}
.toString().slice(20, -8))
}

export {printASCII}